import React, { useMemo } from 'react'
import './index.scss'
import PaymentMethodIcon from './PaymentMethodIcon'
import computeConvenienceFee from './functions/computeConvenienceFee'

const PaymentMethod = ({ name = 'gcash', onSelect = () => { }, selected = false, amount = 0 }) => {
    let style = selected ? 'payment-method payment-method--selected' : 'payment-method'

    let convenienceFee = useMemo(() => computeConvenienceFee(name, amount), [])

    return <div onClick={() => {
        onSelect(name, convenienceFee)
    }} className={style}>{name} Php {convenienceFee}
        <PaymentMethodIcon name={name} /></div>
}

export default PaymentMethod