import React from 'react'
import TopGradient from './TopGradient'

const PageGradient = ({children}) => {
    return <>
        <TopGradient variant='blue'/>
        {children}
    </>
}

export default PageGradient