import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeft from '@material-ui/icons/ArrowBack'

import './index.scss'

import {navigate} from 'gatsby'

const Header = ({ text = "", onClick = () => {navigate(-1)} }) => {
    return <div className='wrapper'>
        <IconButton onClick={onClick}>
            <ArrowLeft />
        </IconButton>
        <div className="user-wallet-header">
            <div className='user-wallet-header__title'>
                <span>{text}</span>
            </div>
        </div>
    </div>
}

export default Header