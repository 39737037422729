function computeConvenienceFee(amount, percentage, fixedFee) {
    let convFee = (amount * percentage) + fixedFee;
    let decimalPlaces = 2;
    let value = convFee;

    return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces).toFixed(decimalPlaces)
  }


export default function (name, amount) {
  console.log(name, amount)
  switch(name){
    case 'paypal': 
      return computeConvenienceFee(amount, 0.05, 2.50)
    case 'creditcard': 
      return computeConvenienceFee(amount, 0.035, 15)
    case 'gcash': 
      return computeConvenienceFee(amount, 0.033, 0)
    case 'bdo': 
      return computeConvenienceFee(0, 0, 0)
    case 'paypal-instant': 
      return computeConvenienceFee(amount, 0.044, 15)
    default: 
      return 0
  }
}

