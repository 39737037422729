import React from 'react'
import Divider from '@material-ui/core/Divider'
import './index.scss'

const TopupDetails = ({amount, convenienceFee}) => {
    return <div className='topup-details'>
        <div className='topup-details__amount'>
            <div className='topup-details__amount-text'>Top up Amount</div>
            <div className='topup-details__amount-value'>{amount}</div>
        </div>

        <div className='topup-details__convenience-fee'>
            <div className='topup-details__convenience-fee-text'>Convenience fee</div>
            <div className='topup-details__convenience-fee-value'> {convenienceFee}</div>
        </div>

        <Divider />

        <div className='topup-details__total'>
            <div className='topup-details__total-text'>Total</div>
            <div className='topup-details__total-value'>{Number(amount) + Number(convenienceFee)}</div>
        </div>

    </div>
}

export default TopupDetails