import React from 'react'
import './index.scss'

const TopGradient = ({variant = "blue"}) => {
    const color = variant === 'blue' ? `top-gradient__blue` : `top-gradient__yellow`
    return <div className={`top-gradient ${color}`}>

    </div> 
}

export default TopGradient