import { navigate } from 'gatsby'
import React, { useCallback, useRef, useState } from 'react'
import { postSubmitProofData, postTransaction, putSubmitProofImage } from '../../../../../../api/public/wallet'
import { getUser } from '../../../../../../services/auth'
import Button from '../../../../components/Button'

import generateRandomReferenceNumber from '../PaymentMethods/PaymentMethod/functions/generateRandomReferenceNumber'

import './index.scss'
const BDOSubmitForm = ({amount}) => {
    let [loading, setLoading] = useState(false)
    let [state, setState] = useState(0)
    let [picture, setPicture]= useState(null)
    let [imageLink, setImageLink] = useState(null)

    const user = getUser()
    const fileInputButton = useRef(null)
    let referenceNumber = generateRandomReferenceNumber()

    const handleButtonClick = useCallback(async (e) => {
        e.preventDefault() 

        if(state === 0){
            fileInputButton.current.click()
        }
        else {
            setLoading(true)
            const data = {
                accountName: user.name,
                amount: amount, 
                accountNumber: user.phone,
                accountEmail: user.email, 
                bankName: "BDO",
                referenceNumber: referenceNumber
            }


            let response = await postSubmitProofData(user.userId, data, user.token)

            console.log(response)


            if(response && response.error){
                return alert('Something happened, please try again')
            }

            let uploadResponse = await putSubmitProofImage(response.body._id, imageLink)

            if(uploadResponse && uploadResponse.error){
                return alert('Something happened, please try again')
            }

            let proof = {
                amount: amount, 
                title: `Topup BDO`, 
                symbol: '+',
                status: 'Pending', 
                referenceNumber: referenceNumber
            }

            let postTransactionResponse = await postTransaction(user.user, proof, user.token)

            if(postTransactionResponse){
                alert("You submitted your proof")
                navigate('/user-wallet')
            }

            setLoading(false)
        }
    }, [state])

    const onChangePicture = useCallback((e) => {
        e.preventDefault()

        if(e.target.files[0]){
            setPicture(URL.createObjectURL(e.target.files[0]))
            setImageLink(e.target.files[0])
            setState(1)
        }
    }, [])

    return <div className='bdo-submit-form'>You can pay using the following bank accounts

        <p>Account Number <br/> 004558007012 </p>
        <p>Account Name <br/> Sparkle Star International Corp.</p>


        <input
          ref={fileInputButton}
          type="file"
          style={{ display: "none" }}
          onChange={onChangePicture}
        />
         <Button loading = {loading} text= {state === 0 ? 'Select proof of transaction': 'Submit proof of transaction'}onClick={handleButtonClick}/>

        {picture  && <img className = 'bdo-submit-form__image' src = {picture}/>}

       
    </div>
}

export default BDOSubmitForm