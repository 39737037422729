import { postTopUpDetails } from "../../../../../../../api/requests"
import { getUser } from "../../../../../../../services/auth"
import postMessage from "../../../../../../../services/react-native/postMessage"

export default async function generatePaymongoLink(method, amount, convenienceFee){

    let user = getUser()

    console.log(Number(amount) + Number(convenienceFee))

    let response = await fetch('https://api.paymongo.com/v1/links', {
        method: 'POST', 
        headers: {
            'accept': 'application/json',
            'authorization': `Basic ${process.env.REACT_APP_PAYMONGO_LINK}`,
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            'data': {
              'attributes': {
                'amount': (Number(amount)  + Number(convenienceFee)) * 100,
                'description': `Top-Up Sparkle Pay`
              }
            }
          })
    })

    let result = await response.json()

    let reference = result.data.attributes.reference_number
    let status = result.data.attributes.status

    
    let topupDetailsData = {
        amount: amount, 
        reference: reference,
        type: method, 
        status: status, 
    }

    let topupResponse = await postTopUpDetails(user.userId, topupDetailsData, user.token)

    if(topupResponse){
        postMessage('PAYMONGO_CHECKOUT', {})
        window.location.href = result.data.attributes.checkout_url
    }
    
}