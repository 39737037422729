import React from 'react'
import PaypalLogo from '../../../../../../../assets/images/paypal.webp'
import CreditCardLogo from '../../../../../../../assets/images/visa.webp'
import GcashLogo from '../../../../../../../assets/images/gcash.webp'
import BDOLogo from '../../../../../../../assets/images/bdo.webp'


const PaymentMethodIcon = ({name = 'gcash'}) => {
    switch(name){
        case 'gcash':
            return <img className='payment-method__image' src={GcashLogo} />
        case 'bdo':
            return <img className='payment-method__image' src={BDOLogo} />
        case 'creditcard': 
            return <img className='payment-method__image' src={CreditCardLogo} />
        case 'paypal': 
            return <img className='payment-method__image' src={PaypalLogo} /> 
    }
}

export default PaymentMethodIcon