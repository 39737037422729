import React from 'react'
import Page from '../../components/SparkleWallet/UserTopUp/Checkout'
import generatePagePropsOnBuildTime from '../../helpers/generatePagePropsOnBuildTime'
import PageGradient from '../../components/PageGradient'


const UserTopupCheckout = (props) => {
    console.log(props.location.state)

    let amount = props?.location?.state?.amount ? props.location.state?.amount : 0

    return <PageGradient> <Page amount={amount} />
    </PageGradient>
}

export default UserTopupCheckout