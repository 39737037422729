import React, {useCallback, useState} from 'react'
import PaymentMethods from './PaymentMethods'
import TopupButton from './TopUpButton'
import TopupDetails from './TopupDetails'

import BDOSubmitForm from './BDOSubmitForm'

const TopupInformation = ({amount = 100}) => {
    let [convenienceFee, setConvenienceFee] = useState(0)
    let [paymentMethod, setPaymentMethod] = useState('')

    let paymentMethodSelect = useCallback((method, convenienceFee) => {
        setPaymentMethod(method)
        setConvenienceFee(convenienceFee)
    }, [convenienceFee, paymentMethod])


    return <>
        <TopupDetails amount = {amount} convenienceFee = {convenienceFee}/>
        {paymentMethod !== 'bdo' ?  <PaymentMethods amount = {amount} onPaymentMethodSelect={paymentMethodSelect} /> : null}
        {paymentMethod === 'bdo' ? <BDOSubmitForm amount = {amount} /> : null}
        <TopupButton setPaymentMethod = {setPaymentMethod} method = {paymentMethod} amount = {amount} convenienceFee = {convenienceFee}/>
    </>
}

export default TopupInformation