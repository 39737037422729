import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

import { postUserBalance } from '../../../../../../../api/public/wallet'
import { postTransaction } from '../../../../../../../api/public/wallet'
import { getUser } from '../../../../../../../services/auth'
import generateReferenceNumber from '../../PaymentMethods/PaymentMethod/functions/generateRandomReferenceNumber'

const PaypalCheckout = ({amount ,convenienceFee}) => {
    let user = getUser()
    let referenceNumber = generateReferenceNumber()
    
    return <PayPalScriptProvider
    options={{
      "client-id": process.env.REACT_PAYPAL_CLIENT_ID,
      currency: 'PHP',
    }}
  >
    <PayPalButtons
      createOrder={({ data }, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: (amount + convenienceFee),
              },
              description: `₱ ${amount} Sparkle Blessing Top-up`,
            },
          ],
          application_context: {
            brand_name: "Sparkle Star International",
            shipping_preference: "NO_SHIPPING",
          },
        })
      }}
      onApprove={(data, actions) => {
        return actions.order.capture().then(async (details) => {

          const paypalData = {
            accountName: user.name,
            netAmount: amount,
            currency: "PH"
          }
          postUserBalance(user.userId, paypalData, user.token)
            .then((data) => {
              if (data) {
                const dataTransac = {
                  amount: amount,
                  title: "Paypal TopUp",
                  symbol: "+",
                  referenceNumber: referenceNumber
                }
                postTransaction(user.userId, dataTransac, user.token)
                  .then(data => {
                    alert(`Transaction completed by ${details.payer.name.given_name} `)
                  })
              }
            })
        })
      }}
      style={{ layout: "vertical" }}
    />
  </PayPalScriptProvider>
}


export default PaypalCheckout
