import React, { useState, useCallback } from 'react'
import PaymentMethod from './PaymentMethod'

const PaymentMethods = ({amount = 0, onPaymentMethodSelect = () => {}}) => {
    const [selectedMethod, selectMethod] = useState('')

    const chooseMethod = useCallback((method = '', convenienceFee = 0) => {
        selectMethod(method)
        onPaymentMethodSelect(method, convenienceFee)
    }, [])


    return <div className='payment-methods'>
        <PaymentMethod amount = {amount} name='gcash' onSelect={chooseMethod} selected={selectedMethod === 'gcash'} />
        <PaymentMethod amount = {amount} name='paypal' onSelect={chooseMethod} selected={selectedMethod === 'paypal'} />
        <PaymentMethod amount =  {amount} name='creditcard' onSelect={chooseMethod} selected={selectedMethod === 'creditcard'} />
        <PaymentMethod amount = {amount} name='bdo' onSelect={chooseMethod} selected={selectedMethod === 'bdo'} />
    </div>
}

export default PaymentMethods