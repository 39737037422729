import React from 'react'
import './index.scss'


import Header from '../../components/Header'
import SparkleLogo from '../../components/SparkleLogo'

import TopupInformation from './TopUpInformation'

const Checkout = ({amount = 100}) => {
    console.log(amount)
    return <div className='user-wallet-topup-checkout'>
        <Header />
        <SparkleLogo />
        <TopupInformation amount = {amount}/>
    </div>
}

export default Checkout