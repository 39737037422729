import React, { useCallback, useState } from 'react'
import Button from '../../../../components/Button'

import generatePaymongoLink from './functions/generatePaymongoLink'
import PaypalCheckout from './PayPalCheckout'


const TopupButton = ({ method, amount, convenienceFee}) => {
    let [loading, setLoading] = useState(false)

    console.log('topup button method', method)
    let paymongoCheckout = useCallback(async () => {
        console.log('setting loading')
        setLoading(true)
        generatePaymongoLink(method, amount, convenienceFee)
    }, [amount, convenienceFee, method, loading])

    return <>
        {method === 'paypal' ? <PaypalCheckout /> : null}
        {method === 'gcash' || method === 'creditcard' ? <Button loading={loading || method === ''} text={`Pay ${Number(amount) + Number(convenienceFee)} using ${method}`} onClick={paymongoCheckout} /> : null}

    </>
}


export default TopupButton